import { useContext } from "react";
import { PageContext } from "./PageProvider";
import { useSearchParams } from "react-router-dom";

function Main() {
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const [searchParams, setSearchParams] = useSearchParams();

    return ( 
        <>
            <h1>prismGames;</h1><br/>
            <p>
                An indie game studio that currently made<br/>
                <button onClick={ () => {
                    setSearchParams(prevParams => {
                        const newParams = new URLSearchParams(prevParams); // Clone the existing search params
                        newParams.set('currentPage', 'gmm'); // Set or update the 'currentPage' parameter
                        return newParams;
                    });                    
                    setCurrentPage("gmm");
                } }>GMM 1</button> 
                <br/> and <br/>
                <button onClick={ () => {
                    setSearchParams(prevParams => {
                        const newParams = new URLSearchParams(prevParams); // Clone the existing search params
                        newParams.set('currentPage', 'gmm2'); // Set or update the 'currentPage' parameter
                        return newParams;
                    });                    
                    setCurrentPage("gmm2");
                } }>GMM 2</button>
                <br/>
                <br/>
                <h3>with over</h3>
                <h1>18k people</h1>
                <h3>on Discord</h3>
                <button onClick={()=> window.open("https://discord.gg/granny-multiplayer-mod-gmm-1051904251091230860", "_blank")}>Join our Discord server</button>
            </p>
        </>
    );
}

export default Main;