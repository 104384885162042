import { React, createContext, useState } from 'react';

// Create the context
export const PageContext = createContext();

// Create a provider component
export const PageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("mainPage");

  return (
    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </PageContext.Provider>
  );
};