import gplayBadge from "./Media/GooglePlayBadge.png"

// <img src={gplayBadge} className="googlePlayBadge" alt="Get it from Google Play" />
function GMM2() {
    return (
        <>
            Work in progress!
        </>
    );
}

export default GMM2;