import PrivacyPolicyBtn from "./Tabs/PrivacyPolicyBtn";
import '../Styles/Components/Layout/TopBar.css'
import MainPage from "./Tabs/MainPage";
function TopBar() {
    return ( 
        <div className="topBar">
            <MainPage />
            <PrivacyPolicyBtn/>
        </div>
    );
}

export default TopBar;