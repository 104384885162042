import { useContext } from 'react';
import logo from '../../Media/logo.png'
import { PageContext } from '../../PageProvider';
import { useSearchParams } from 'react-router-dom';
function MainPage() {
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const [searchParams, setSearchParams] = useSearchParams();

    return ( 
        <button onClick={ () => {
            setSearchParams(prevParams => {
                const newParams = new URLSearchParams(prevParams); // Clone the existing search params
                newParams.set('currentPage', 'mainPage'); // Set or update the 'currentPage' parameter
                return newParams;
            });                    
            setCurrentPage("mainPage");
        } } className="button-none button-nohoverfx button-nofocusfx">
            <img src={logo} width={64} height={64}/>
        </button>
    );
}

export default MainPage;