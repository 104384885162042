import { useContext } from 'react';
import { PageContext } from '../../PageProvider';
import { useSearchParams } from 'react-router-dom';
function PrivacyPolicyBtn() {
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const [searchParams, setSearchParams] = useSearchParams();

    return ( 
        <button onClick={ () => {
            setSearchParams(prevParams => {
                const newParams = new URLSearchParams(prevParams); // Clone the existing search params
                newParams.set('currentPage', 'privacyPolicy'); // Set or update the 'currentPage' parameter
                return newParams;
            });                    
            setCurrentPage("privacyPolicy");
        } } className="button-none button-nohoverfx button-nofocusfx">
            <p>Privacy Policy</p>
        </button>
    );
}

export default PrivacyPolicyBtn;