import gplayBadge from "./Media/GooglePlayBadge.png"

// <img src={gplayBadge} className="googlePlayBadge" alt="Get it from Google Play" />
function GMM() {
    return (
        <>
            Our first game, and the most popular one with over 5 million downloads!
            <h1>Join our community now!</h1>
            <a target="_blank"
               href="https://play.google.com/store/apps/details?id=com.UnityUserco.EscapeHauntedHouseOnline">
                <h2>Get it from Google Play</h2>
            </a>
            <p className="googleLLCTrademark">Google Play is a trademark of Google LLC.</p>
        </>
    );
}

export default GMM;